import React from "react";
import "./css/Dashboard.css";
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Link, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import emailSendPng from '../assets/images/email-send.png';
import deletePng from '../assets/images/delete.png';
import viewPng from '../assets/images/view.png';
import logoutPng from '../assets/images/logout.png';
import apiConfig from '../config';
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";
import StatusAlert, { StatusAlertService } from 'react-status-alert';
import 'react-status-alert/dist/status-alert.css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Dashboard = () => {
    const [rows, setRows] = React.useState([]);
    const [rowsVisitors, setRowsVisitors] = React.useState([]);
    const { apiBaseURL } = apiConfig;
    const [value, setValue] = React.useState(0);
    const [alertId, setAlertId] = React.useState('');
    const [rowData, setRowData] = React.useState(null);
    const navigate = useNavigate();

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'full_name',
            headerName: 'Full name',
            flex: 1
        },
        {
            field: 'email',
            headerName: 'E-mail',
            flex: 1
        },
        {
            field: 'company',
            headerName: 'Company',
            flex: 1
        },
        {
            field: 'message',
            headerName: 'Message',
            flex: 2
        },
        {
            headerName: 'Actions',
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <>
                        <Tooltip title="Send Email">
                            <IconButton size="medium" onClick={() => {
                                window.open(`mailto:${cellValues?.row?.email}`);
                            }}>
                                <img src={emailSendPng} width={24} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Remove">
                            <IconButton size="medium" onClick={() => {
                                handleDeleteRow(cellValues?.row?.id);
                            }}>
                                <img src={deletePng} width={20} />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }
        }
    ];

    const columnsVisitors = [
        // { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'source_ip',
            headerName: 'Source IP',
            flex: 1
        },
        {
            field: 'location',
            headerName: 'Country',
            flex: 1,
            valueGetter: (params) => {
                const getCountryName = new Intl.DisplayNames(['en'], { type: 'region' });
                if (params?.row?.geo_information?.country) {
                    return getCountryName.of(params?.row?.geo_information?.country);
                }
                return "None";
            }
        },
        {
            field: 'referrer',
            headerName: 'Referrer',
            flex: 1,
            valueGetter: (params) => `${params.row?.referrer || "None"}`
        },
        {
            field: 'url',
            headerName: 'URL',
            flex: 2
        },
        {
            field: 'createdAt',
            headerName: 'Date & Time',
            flex: 1,
            valueGetter: (params) => {
                const date = new Date(params?.row?.createdAt);
                return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
            }
        },
        {
            headerName: 'Actions',
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <>
                        <Tooltip title="View details">
                            <IconButton size="medium" onClick={() => {
                                setRowData(cellValues?.row)
                                setOpenDialog(true);
                            }}>
                                <img src={viewPng} width={20} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Remove">
                            <IconButton size="medium" onClick={() => {
                                handleDeleteRowVisitor(cellValues?.row?.id);
                            }}>
                                <img src={deletePng} width={20} />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }
        }
    ];

    React.useEffect(() => {
        handleFetch();
        handleFetchVisitor();
        handleCheckToken();
    }, []);

    const handleCheckToken = () => {
        fetch(`${apiBaseURL}/api/auth/check-token`, {
            method: 'GET',
            headers: {
                'x-access-token': localStorage.getItem('token')
            }
        }).then(res => {
            console.log(res);
            if (res.status == 401) {
                handleLogout();
            }
            return res.json();
        })
            .then(data => {
                console.log(data);
            });
    }

    const handleFetch = () => {
        fetch(`${apiBaseURL}/api/clients`, {
            method: 'GET',
            headers: {
                'x-access-token': localStorage.getItem('token')
            }
        }).then(res => res.json())
            .then(data => {
                console.log(data);
                setRows(data);
            });
    }

    const handleFetchVisitor = () => {
        fetch(`${apiBaseURL}/api/visitors`, {
            method: 'GET',
            headers: {
                'x-access-token': localStorage.getItem('token')
            }
        }).then(res => res.json())
            .then(data => {
                console.log(data);
                setRowsVisitors(data);
            });
    };

    const handleDeleteRow = (id) => {
        fetch(`${apiBaseURL}/api/clients/${id}`, {
            method: 'DELETE',
            headers: {
                'x-access-token': localStorage.getItem('token')
            }
        }).then(res => res.json())
            .then(data => {
                console.log(data);
                if (data?.message == "Client was deleted successfully!") {
                    handleFetch();
                    const aId = StatusAlertService.showSuccess(data?.message);
                    setAlertId(aId);
                }
            });
    }

    const handleDeleteRowVisitor = (id) => {
        console.log(id)
        fetch(`${apiBaseURL}/api/visitors/${id}`, {
            method: 'DELETE',
            headers: {
                'x-access-token': localStorage.getItem('token')
            }
        }).then(res => res.json())
            .then(data => {
                console.log(data);
                if (data?.message == "Visitor was deleted successfully!") {
                    handleFetchVisitor();
                    const aId = StatusAlertService.showSuccess(data?.message);
                    setAlertId(aId);
                }
            });
    }

    const handleLogout = () => {
        localStorage.clear();
        navigate('/admin');
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setRowData(null);
    }

    return (
        <div className="main" style={{ display: 'block' }}>
            <StatusAlert autoHideTime={12000} withCloseIcon withIcon />
            <div style={{ color: "#404040", width: "100%", justifyContent: 'flex-end', display: 'flex', paddingBottom: "10px" }}>
                <Link underline="none" onClick={handleLogout} sx={{ display: 'flex', color: "#3ca1ad", cursor: "pointer" }}>
                    <img src={logoutPng} width={20} />&nbsp;Logout
                </Link>
            </div>
            <div>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant='fullWidth'>
                        <Tab label="Clients" {...a11yProps(0)} wrapped />
                        <Tab label="Visitors" {...a11yProps(1)} wrapped />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        // pageSize={5}
                        // rowsPerPageOptions={[5]}
                        checkboxSelection={false}
                        disableSelectionOnClick={false}
                        // autoHeight
                        sx={{ minHeight: 400 }}

                    />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <DataGrid
                        rows={rowsVisitors}
                        columns={columnsVisitors}
                        // pageSize={5}
                        // rowsPerPageOptions={[5]}
                        checkboxSelection={false}
                        disableSelectionOnClick={false}
                        // autoHeight
                        sx={{ minHeight: 400 }}
                    />
                </TabPanel>
            </div>
            <Dialog
                open={openDialog}
                maxWidth="md"
                onClose={handleCloseDialog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogTitle>Details</DialogTitle>
                <DialogContent dividers>
                    <Container>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant="caption" fontWeight={"bold"}>IP Address</Typography>
                                <Typography variant='body2'>
                                    {rowData?.source_ip}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption" fontWeight={"bold"}>User Agent</Typography>
                                <Typography variant='body2'>
                                    {rowData?.user_agent}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption" fontWeight={"bold"}>Country (Code)</Typography>
                                <Typography variant='body2'>
                                    {rowData?.geo_information?.country || "None"}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption" fontWeight={"bold"}>Timezone</Typography>
                                <Typography variant='body2'>
                                    {rowData?.geo_information?.timezone || "None"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};

export default Dashboard;