import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./css/NavHeader.css";
import "./css/Sidemenu.css";

const NavHeader = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      <nav className={pathname != "/" && "filled"}>
        <div
          className="logo"
          onClick={() => {
            navigate("/");
          }}
        >
          <img src="https://pesaswapbackups.blob.core.windows.net/uploads/smarttrade/smarttrade.png" />
          <div className="brand">
            <span>SMART TRADE</span>
            <span>AFRICA</span>
          </div>
        </div>
        <ul>
          <li className="navlist-menu">
            <Link to="/about">About Us</Link>
          </li>
          <li className="navlist-menu">
            <Link to="/contact">Contact Us</Link>
          </li>
          <li className="navlist-icon">
            <input type="checkbox" id="active" />
            <label htmlFor="active" className="menu-btn">
              <i className="fas fa-bars"></i>
            </label>
            <div className="wrapper">
              <ul>
                <li>
                  <Link
                    to="/"
                    onClick={() => {
                      document.getElementsByClassName("menu-btn")[0].click();
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    onClick={() => {
                      document.getElementsByClassName("menu-btn")[0].click();
                    }}
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    onClick={() => {
                      document.getElementsByClassName("menu-btn")[0].click();
                    }}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default NavHeader;
