import React from "react";
import "./css/Footer.css";

const FloatingSidebar = () => {
  return (
    <div className="floating-menu">
      <div className="floating-title">PARTNER SITES</div>
      <ul className="floating-nav">
        <li>
          <a href="https://qce.smarttradeafrica.com" target="_blank">Quosvis Carbon Ecosystem</a>
        </li>
      </ul>
    </div>
  );
};

export default FloatingSidebar;
