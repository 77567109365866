import React from "react";
import "./css/Footer.css";
import facebookLogo from "../assets/social-logos/facebook.png";
import instagramLogo from "../assets/social-logos/instagram.png";
import linkedInLogo from "../assets/social-logos/linkedin.png";
import twitterLogo from "../assets/social-logos/twitter.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-distributed">
      <div className="footer-right">
        <a href="#">
          <img src={facebookLogo} />
        </a>
        <a
          href={"https://instagram.com/smarttradeafrica?igshid=YmMyMTA2M2Y="}
          target={"_blank"}
        >
          <img src={instagramLogo} />
        </a>
        <a
          href={"https://www.linkedin.com/company/smarttradeafrica/"}
          target={"_blank"}
        >
          <img src={linkedInLogo} />
        </a>
        <a
          href={
            "https://twitter.com/SmartTradeAfri?t=j5ppxVzMafEUY0d0rcVP_Q&s=08"
          }
          target={"_blank"}
        >
          <img src={twitterLogo} />
        </a>
      </div>
      <div className="footer-left">
        <p className="footer-links">
          <a className="link-1" href="#">
            Home
          </a>
          <Link to="/about">About Us</Link>
          <Link to="/contact">Contact Us</Link>
        </p>

        <p>&copy; 2022 Smart Trade Africa. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
