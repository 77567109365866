import React from "react";
import { Outlet } from "react-router-dom";
import NavHeader from "./NavHeader";
import Footer from "./Footer";
import CookieBanner, { Cookies } from 'react-cookie-banner';
import cookiePng from '../assets/images/cookie.png';
import apiConfig from '../config';
import FloatingSidebar from "./FloatingSidebar";

const bannerStyles = {
  banner: {
    height: 57,
    background: `rgba(52, 64, 81, 0.88) url(${cookiePng}) 20px 50% no-repeat`,
    backgroundSize: '30px 30px',
    backgroundColor: '',
    fontSize: '15px',
    position: 'fixed',
    width: '100%',
    bottom: 0
  },
  button: {
    border: '1px solid white',
    borderRadius: 4,
    width: 66,
    height: 32,
    lineHeight: '32px',
    background: 'transparent',
    color: 'white',
    fontSize: '14px',
    fontWeight: 600,
    opacity: 1,
    right: 20,
    marginTop: -18
  },
  message: {
    display: 'block',
    padding: '9px 67px',
    lineHeight: 1.3,
    textAlign: 'left',
    marginRight: '50%',
    color: 'white'
  },
  link: {
    textDecoration: 'none',
    fontWeight: 'bold'
  }
};

const Layout = () => {
  const { apiBaseURL } = apiConfig;
  const [pixel, setPixel] = React.useState("");
  const [cookieAccepted, setCookieAccepted] = React.useState(false);
  const [geoLocation, setGeoLocation] = React.useState([]);

  React.useEffect(() => {
    const cookies = new Cookies();
    const consent = cookies.get('user-consent');

    if (consent) setCookieAccepted(true);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var longLat = [position.coords.latitude, position.coords.longitude];
        setGeoLocation(longLat);
      }, function (error) {
        switch (error.code) {
          case error.PERMISSION_DENIED:
            console.log("User denied the request for Geolocation.");
            break;
          case error.POSITION_UNAVAILABLE:
            console.log("Location information is unavailable.");
            break;
          case error.TIMEOUT:
            console.log("The request to get user location timed out.");
            break;
          case error.UNKNOWN_ERROR:
            console.log("An unknown error occurred.");
            break;
        }
      });
    } else {
      console.log("Geolocation is not supported by this browser");
    }
  }, []);

  React.useEffect(() => {
    if (cookieAccepted) {
      const params = {
        url: window.location.href,
        referrer: document.referrer,
        location: geoLocation
      }
      let query = Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');

      setPixel(`${apiBaseURL}/api/visitors/track?${query}`);
    }
  }, [cookieAccepted]);

  return (
    <div>
      <NavHeader />
      <Outlet />
      <FloatingSidebar />
      <Footer />
      <CookieBanner
        styles={bannerStyles}
        message="We use cookies to personalize content and analyze access to our website. By continuing to visit this website you agree to our use of cookies."
        buttonMessage='Close'
        onAccept={() => {
          setCookieAccepted(true);
        }}
        dismissOnClick={true}
        dismissOnScroll={false}
        cookie="user-consent" />
      <img src={pixel} />
    </div>
  );
};

export default Layout;
