import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./static-site/css/Common.css";
import Layout from "./static-site/Layout";
import Dashboard from "./static-site/Dashboard";
import AdminLogin from "./static-site/AdminLogin";
import { ProtectedRoute } from "./container/ProtectedRoute";

const Home = React.lazy(() => import("./static-site/Home"));
const About = React.lazy(() => import("./static-site/About"));
const Contact = React.lazy(() => import("./static-site/Contact"));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <React.Suspense fallback={<>...</>}>
                <Home />
              </React.Suspense>
            }
          />
          <Route
            path="about"
            element={
              <React.Suspense fallback={<>...</>}>
                <About />
              </React.Suspense>
            }
          />
          <Route
            path="contact"
            element={
              <React.Suspense fallback={<>...</>}>
                <Contact />
              </React.Suspense>
            }
          />
        </Route>
        <Route path="admin" element={<Layout />}>
          <Route
            path=""
            element={
              <React.Suspense fallback={<>...</>}>
                <AdminLogin />
              </React.Suspense>
            }
          />
          <Route
            path="dashboard"
            element={
              <React.Suspense fallback={<>...</>}>
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
