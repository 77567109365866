import React from "react";
import "./css/AdminLogin.css";
import { DataGrid } from '@mui/x-data-grid';
import { IconButton, Tooltip } from "@mui/material";
import emailSendPng from '../assets/images/email-send.png';
import deletePng from '../assets/images/delete.png';
import StatusAlert, { StatusAlertService } from 'react-status-alert';
import 'react-status-alert/dist/status-alert.css';
import apiConfig from '../config';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {
    const { apiBaseURL } = apiConfig;
    const [loginForm, setLoginForm] = React.useState({
        email: '',
        password: ''
    });
    const [alertId, setAlertId] = React.useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginForm({ ...loginForm, [name]: value });
    }

    const handleSubmit = () => {
        const { email, password } = loginForm;
        let error_list = ["Please enter the following fields:"];

        if (email.trim().length < 1) {
            error_list.push("E-mail");
        }
        if (password.trim().length < 1) {
            error_list.push("Password");
        }

        if (error_list.length > 1) {
            const aId = StatusAlertService.showError(error_list.join(', '));
            setAlertId(aId);
            return;
        }

        fetch(`${apiBaseURL}/api/auth/signin`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password })
        }).then(res => res.json())
            .then(data => {
                console.log(data);
                if (data?.id) {
                    localStorage.setItem("token", data?.accessToken);
                    navigate('/admin/dashboard');
                    return;
                }
                const aId = StatusAlertService.showError(data?.message);
                setAlertId(aId);
            })
    }

    return (
        <div className="main">
            <StatusAlert autoHideTime={12000} withCloseIcon withIcon />
            <div className="screen-body">
                <div className="screen-body-item left">
                    <div className="app-title"></div>
                </div>
                <div className="screen-body-item">
                    <div className="app-form" style={{ color: "#404040" }}>
                        <h2>Administrator Login</h2>
                        <br />
                        <div className="app-form-group">
                            <input className="app-form-control" type="email" name="email" onChange={handleChange} value={loginForm?.email} placeholder="E-mail" />
                        </div>
                        <div className="app-form-group">
                            <input className="app-form-control" type="password" name="password" onChange={handleChange} value={loginForm?.password} placeholder="Password" />
                        </div>
                        <div className="app-form-group buttons">
                            <button className="app-form-button" onClick={handleSubmit}>SIGN IN</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AdminLogin;